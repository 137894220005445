<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            showSearch: false,
            page: 1,
            listPage: 3,
            keywords: null,
            slug: null,
        };
    },
    computed: {
        ...mapGetters({
            MostNews: 'news/MostNews',
            ListNews: 'news/ListNews',
            Category: 'news/Category',
        }),
    },
    methods: {
        onSearch(slug) {
            if (slug) {
                this.$store.dispatch('news/get_NewsOfCategory', {
                    article_title: this.keywords,
                    slug,
                    page: 1,
                });
                return;
            }
            this.$store.dispatch('news/get_AllNews', {
                page: this.page,
                article_title: this.keywords,
            });
        },
    },
    watch: {
        page: {
            handler() {
                if (this.page !== this.ListNews.current) {
                    if (this.slug) {
                        this.$store.dispatch('news/get_NewsOfCategory', {
                            article_title: this.keywords,
                            slug: this.slug,
                            page: this.page,
                        });
                        return;
                    }
                    this.$store.dispatch('news/get_AllNews', {
                        page: this.page,
                        article_title: this.keywords,
                    });
                }
            },
        },
    },
    created() {
        this.$store.dispatch('news/get_MostNews');
        this.$store.dispatch('news/get_AllNews');
        this.$store.dispatch('news/get_Category');
    },
};
</script>
<template>
    <b-container fluid class="news">
        <b-container class="banner">
            <img
                src="~@/assets/images/new/baner.png"
                class="banner-img"
                alt=""
            />
            <div class="search-bar">
                <div class="title">{{ $t('news') }}</div>
            </div>
        </b-container>
        <b-container>
            <b-row>
                <b-col
                    lg="8"
                    xl="7"
                    class="news-left"
                    v-if="ListNews && ListNews.data && ListNews.data.length > 0"
                >
                    <b-row
                        class="new-main"
                        v-for="news in ListNews.data"
                        :key="news.article_id"
                    >
                        <b-col sm="5" md="5" lg="5">
                            <div class="project-item">
                                <div class="project-img">
                                    <img :src="news.article_thumbnail" alt="" />
                                </div>
                                <div class="project-name"></div>
                                <div class="project-line"></div>
                            </div>
                        </b-col>
                        <b-col sm="7" md="7" lg="7">
                            <div class="news-content">
                                <router-link
                                    :to="{
                                        name: 'NewsDetail',
                                        params: {
                                            slug: news.article_slug,
                                            type: 'tin-tuc',
                                        },
                                    }"
                                >
                                    <h3>{{ news.article_title }}</h3>
                                </router-link>

                                <h5>
                                    {{ news.article_blog_name }} -
                                    {{ getDateTime(news.created_at) }}
                                </h5>
                                <p>
                                    {{ news.article_description }}
                                </p>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col lg="8" xl="7" class="news-left" v-else>KHÔNG CÓ TIN TỨC MÀ BẠN CẦN</b-col>
                <b-col lg="4" xl="5" class="news-right">
                    <div class="news-right-content">
                        <div class="input">
                            <b-button @click="onSearch">
                                <img
                                    src="~@/assets/images/icons/search.png"
                                    alt=""
                                />
                            </b-button>
                            <b-form-input
                                :placeholder="$t('search')"
                                v-model="keywords"
                                @change="onSearch"
                            ></b-form-input>
                        </div>
                        <div class="question-content">
                            <div class="question-item">
                                <div
                                    class="question-header"
                                    @click="showSearch = !showSearch"
                                >
                                    <div class="text">{{ $t('category') }}</div>
                                    <div
                                        class="icon"
                                        :class="{ active: showSearch == true }"
                                    >
                                        <img
                                            src="~@/assets/images/icons/arrow.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div class="question-container-2">
                                    <transition
                                        name="custom-classes-transition"
                                        enter-active-class="animate__animated animate__fadeInDown"
                                        leave-active-class="animate__animated animate__fadeOutUp"
                                    >
                                        <div
                                            class="question-body"
                                            v-if="showSearch"
                                        >
                                            <p
                                                v-for="category in Category"
                                                :key="`category-${category.blog_id}`"
                                                @click="
                                                    onSearch(category.blog_slug)
                                                "
                                            >
                                                {{ category.blog_name }}
                                            </p>
                                        </div>
                                    </transition>
                                </div>
                            </div>
                        </div>
                        <div class="news-right-text">
                            <h4>{{ $t('highlightAticle') }}</h4>
                            <div class="line">
                                <img
                                    src="~@/assets/images/new/line.png"
                                    alt=""
                                />
                            </div>
                            <b-row
                                v-for="most in MostNews"
                                :key="`most-${most.article_id}`"
                                class="mb-3 highlight-new mx-0"
                            >
                                <b-col cols="3" class="px-1 mr-0">
                                    <div class="project-item">
                                        <div class="project-img">
                                            <img
                                                :src="most.article_thumbnail"
                                                alt=""
                                            />
                                        </div>
                                        <div class="project-name"></div>
                                        <div class="project-line"></div>
                                    </div>
                                </b-col>
                                <b-col cols="9" class="p-0 pl-2">
                                    <div class="news-content">
                                        <router-link
                                            :to="{
                                                name: 'NewsDetail',
                                                params: {
                                                    slug: most.article_slug,
                                                    type: 'tin-tuc',
                                                },
                                            }"
                                        >
                                            <h3>{{ most.article_title }}</h3>
                                        </router-link>
                                        <h5>
                                            {{ most.article_blog_name }} -
                                            {{ getDateTime(most.created_at) }}
                                        </h5>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" v-if="ListNews.total > 1">
                    <div class="pagination">
                        <div
                            v-for="indexPage in ListNews.total"
                            :key="indexPage"
                            class="dot"
                            :class="page == indexPage ? 'dot-active' : ''"
                            @click="page = indexPage"
                        ></div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<style lang="scss">
.news {
    padding: 50px 0;
    .container {
        position: relative;
        min-height: 300px;
        border-radius: 25px;
        @media (max-width: 575px) {
            max-width: calc(100% - 30px);
        }
        @media (min-width: 1200px) {
            max-width: 1200px;
        }
        @media (min-width: 1500px) {
            max-width: 1400px;
        }

        img.banner-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 25px;
        }
        .search-bar {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            height: max-content;
            width: max-content;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .title {
                font-family: 'Roboto', sans-serif;
                color: #fff;
                font-size: 40px;
                font-weight: 600;
                text-align: center;
                text-transform: uppercase;
                margin-bottom: 0px;
                @media (max-width: 991px) {
                    font-size: 30px;
                }
                @media (max-width: 767px) {
                    font-size: 26px;
                }
            }
        }
    }
    .news-left {
        margin-top: 70px;
        padding-left: 50px;
        @media (max-width: 991px) {
            padding-left: 15px;
        }
        .new-main {
            margin-bottom: 50px;
            .project-item {
                border-radius: 10px;
                box-shadow: 5px 5px 10px #00000058;
                width: 100%;
                @media (max-width: 767px) {
                    width: 100%;
                    max-width: 414px;
                    margin: 0 auto;
                }
                .project-img {
                    position: relative;
                    cursor: pointer;
                    &:hover {
                        .content-project {
                            opacity: 1;
                            transition: all 1s;
                        }
                    }
                    img {
                        border-radius: 10px;
                        width: 100%;
                        height: auto;
                    }
                    @media (max-width: 575px) {
                        width: 100%;
                        overflow: hidden;
                        margin: 0;
                        padding-top: 56.25%;
                        border-radius: 10px;
                        position: relative;
                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            width: 100%;
                            transform: translate(-50%, -50%);
                        }
                    }
                    .content-project {
                        opacity: 0;
                        display: flex;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background-color: #00000076;
                        overflow: hidden;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 5px;
                        border-radius: 10px;
                        h3 {
                            color: #ffff;
                            font-size: 16px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            font-size: 14px;
                        }
                        p {
                            color: #ffff;
                            font-size: 14px;
                            text-align: center;
                            display: -webkit-box;
                            line-height: 20px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            margin-bottom: 0px;
                        }
                    }
                }
                .project-name {
                    width: 100%;
                    height: 70px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    p {
                        text-align: center;
                        display: -webkit-box;
                        line-height: 30px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        font-weight: bold;
                        font-size: 20px;
                        margin-bottom: 0px;
                        @media (max-width: 575px) {
                            font-size: 16px;
                            line-height: 25px;
                        }
                    }
                }
                .project-line {
                    width: 90%;
                    height: 8px;
                    background-color: #363795;
                    border-radius: 10px;
                    margin: 0 auto;
                }
            }
            .news-content {
                @media (max-width: 575px) {
                    margin-top: 30px;
                    text-align: center;
                }
                h3 {
                    color: #003189;
                    text-transform: uppercase;
                    font-weight: 800;
                    margin-bottom: 5px;
                    font-size: 28px;
                    text-align: left;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    @media (max-width: 767px) {
                        font-size: 20px;
                    }
                    @media (max-width: 575px) {
                        text-align: center;
                    }
                }
                h5 {
                    font-style: italic;
                    font-size: 16px;
                    font-weight: 700;
                    margin-bottom: 40px;
                    text-transform: capitalize;
                    @media (max-width: 575px) {
                        margin-bottom: 20px;
                    }
                }
                p {
                    display: -webkit-box;
                    line-height: 30px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    font-weight: 500;
                }
            }
        }
    }
    .news-right {
        padding: 0px;
        .news-right-content {
            width: 100%;
            padding: 70px 50px 50px 50px;
            background-color: #f7f7f7;
            height: auto;
            @media (max-width: 991px) {
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            .input {
                border: 1px solid #000093;
                background: rgba($color: #ffffff, $alpha: 0.4);
                height: 45px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                @media (max-width: 575px) {
                    height: 40px;
                }
                button {
                    flex: 0 0 60px;
                    height: 45px;
                    padding: 5px;
                    background: transparent;
                    border: 0;
                    outline: none;
                    box-shadow: none;
                    @media (max-width: 575px) {
                        height: 40px;
                    }
                    img {
                        width: auto;
                        height: 100%;
                    }
                }
                input {
                    border: 0;
                    background: transparent;
                    box-shadow: none;
                    color: #000;
                    font-weight: 500;
                    &::placeholder {
                        color: #333;
                        opacity: 0.9;
                        font-style: italic;
                    }
                }
            }
            .question-content {
                margin: auto;
                width: 100%;
                margin-bottom: 70px;
                @media (max-width: 991px) {
                    margin-bottom: 30px;
                }
                .question-item {
                    padding: 10px 2px;
                    overflow: hidden;
                    @media (max-width: 991px) {
                        padding: 10px 1px;
                    }
                    .question-header {
                        cursor: pointer;
                        height: 50px;
                        width: 100%;
                        background: #fff;
                        border-radius: 25px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0px 15px 0px 5%;
                        box-shadow: 1px 1px 5px
                            rgba($color: #000000, $alpha: 0.25);
                        position: relative;
                        z-index: 10;
                        @media (max-width: 575px) {
                            height: 40px;
                        }
                        .text {
                            font-weight: 800;
                            font-family: 'Roboto', sans-serif;
                            font-size: 18px;
                            flex: 1 1 auto;
                            text-align: center;
                            color: #003189;
                        }
                        .icon {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex: 0 0 35px;
                            img {
                                height: 35px;
                                width: 35px;
                                @media (max-width: 575px) {
                                    height: 30px;
                                    width: 30px;
                                }
                            }
                            &.active {
                                transition: all 0.5s;
                                transform: rotate(-90deg);
                            }
                        }
                    }
                    .question-container-2 {
                        border-radius: 15px;
                        .question-body {
                            box-shadow: 3px 6px 5px
                                rgba($color: #000000, $alpha: 0.25);
                            border-radius: 15px;
                            margin: 0px auto 15px;
                            background: #fff;
                            height: auto;
                            width: 100%;
                            padding: 15px 5%;
                            font-weight: 600;
                            text-align: center;
                            max-width: 280px;
                            p {
                                width: 100%;
                                border-bottom: 1px solid #333;
                                color: #0c00ff;
                                cursor: pointer;
                                margin-bottom: 0px;
                                padding: 5px;
                                &:last-child {
                                    border: none;
                                }
                                &:hover {
                                    background-color: #0c00ff;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
            .news-right-text {
                h4 {
                    color: #003189;
                    margin-bottom: 0px;
                    font-weight: 800;
                    font-size: 30px;
                }
                .line {
                    width: 100%;
                    max-width: 300px;
                    margin: 0 auto;
                    margin-bottom: 30px;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                .highlight-new {
                    @media (max-width: 991px) {
                        max-width: 400px;
                    }
                    .project-item {
                        border-radius: 5px;
                        box-shadow: 3px 3px 5px #00000058;
                        width: 100%;
                        @media (max-width: 767px) {
                            width: 100%;
                            max-width: 414px;
                        }
                        .project-img {
                            position: relative;
                            cursor: pointer;
                            &:hover {
                                .content-project {
                                    opacity: 1;
                                    transition: all 1s;
                                }
                            }
                            img {
                                border-radius: 5px;
                                width: 100%;
                                height: auto;
                            }
                            @media (max-width: 575px) {
                                width: 100%;
                                overflow: hidden;
                                margin: 0;
                                padding-top: 56.25%;
                                border-radius: 10px;
                                position: relative;
                                img {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    width: 100%;
                                    transform: translate(-50%, -50%);
                                }
                            }
                            .content-project {
                                opacity: 0;
                                display: flex;
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                background-color: #00000076;
                                overflow: hidden;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                padding: 5px;
                                border-radius: 5px;
                                h3 {
                                    color: #ffff;
                                    font-size: 16px;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                }
                                p {
                                    color: #ffff;
                                    font-size: 14px;
                                    text-align: center;
                                    display: -webkit-box;
                                    line-height: 20px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    -webkit-line-clamp: 3;
                                    -webkit-box-orient: vertical;
                                    margin-bottom: 0px;
                                }
                            }
                        }
                        .project-name {
                            width: 100%;
                            height: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 10px;
                            p {
                                text-align: center;
                                display: -webkit-box;
                                line-height: 30px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                font-weight: bold;
                                font-size: 20px;
                                margin-bottom: 0px;
                                @media (max-width: 575px) {
                                    font-size: 16px;
                                    line-height: 25px;
                                }
                            }
                        }
                        .project-line {
                            width: 100%;
                            max-width: 70px;
                            height: 3px;
                            background-color: #363795;
                            border-radius: 10px;
                            margin: 0 auto;
                        }
                    }
                    .news-content {
                        h3 {
                            color: #003189;
                            text-transform: uppercase;
                            font-weight: 800;
                            margin-bottom: 5px;
                            font-size: 18px;
                            text-align: left;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                        h5 {
                            font-style: italic;
                            font-size: 14px;
                            font-weight: 700;
                            margin-bottom: 40px;
                            text-align: left;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }
    .pagination {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 100px;
        @media (max-width: 575px) {
            margin-top: 20px;
        }
        .dot {
            cursor: pointer;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: #999999;
            margin: 0px 10px;
            &.dot-active {
                background-color: #363795;
            }
        }
    }
}
</style>
